import $api from "@http/index.js";

export default class StatisticService {
  static async get() {
    return $api.get("/statistic");
  }
  static async change(data) {
    return $api.post("/statistic", data);
  }
  static async reset(data) {
    return $api.put("/statistic/reset");
  }
}
