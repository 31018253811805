import React, { useState, Fragment } from "react";
import "./PlayerCard.sass";
import { observer } from "mobx-react-lite";
import playerState from "@store/player";
import playersState from "@store/players";
// import teamsState from "@store/teams-state";
// import gameState from "@store/game-state";
// import MyInput from "@components/UI/MyInput/MyInput";
// import MyButton from "@components/UI/MyButton/MyButton";
import { API_URL } from "@http";
// import cross from "@assets/imgs/cross.svg";

import ControlBar from "./ControlBar/ControlBar";
import Perks from "./Perks";
import event from "../../../event";

import coinImg from "@assets/imgs/coin.png";

function PlayerCard({
  player,
  imgSize = "a",
  showControllers = false,
  showPerks = false,
  showName = false,
  showKush = false,
  showPrize = false,
  classes = "",
  onClick = () => {},
  hideName = false,

  showPrizes = false,
}) {
  let isCurrentPlayer = playerState?.player?._id == player?._id;
  let { players } = playersState;

  let currentPlayer = players.find((p) => p?._id == playerState?.player?._id);

  let showLider =
    currentPlayer && player?.lider && player?.team == currentPlayer.team;

  function showProfilePrize() {
    if (isCurrentPlayer && player.personalPrize) {
      event.dispatch("toast", {
        text: "Ваша награда:",
        subText: player.personalPrize,
      });
    }
  }

  let imgFormat =
    imgSize === "a" ? player.avatar : player.avatar + "-" + imgSize;

  return (
    <div onClick={onClick} className={`player-card ${classes} `}>
      {showPerks ? <Perks player={player} /> : null}

      {showLider ? <p style={{ textAlign: "center" }}>Лидер</p> : null}

      <img
        alt={player.name}
        src={`${API_URL}/images/${imgFormat}.png`}
        className="player-card_avatar"
      />
      {showControllers ? <ControlBar player={player} /> : null}

      {showName ? (
        <h4
          onClick={showProfilePrize}
          className={`player-card_name ${isCurrentPlayer ? "current" : ""}`}
        >
          {player.name}
        </h4>
      ) : null}

      {showKush && player.kush ? (
        <div className="player-card_kush">
          <p> {player.kush}</p>
          <img src={coinImg} />
        </div>
      ) : null}
    </div>
  );
}

export default observer(PlayerCard);
